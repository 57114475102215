// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { AppBar, Link, Toolbar } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Logo, LogoSize } from '../../elements/Logo/Logo';
import { AppProfileMenu } from '../AppProfileMenu/AppProfileMenu';
import { withStyles } from '@material-ui/core/styles';
import { AppSearchBar } from '../AppSearchBar/AppSearchBar';
import { AppLinkWithSubNavigation } from './AppLinkWithSubNavigation';
import { IAppSubNavigationItem, SubNavItemType } from './AppSubNavigationList';
import { APP_ROUTES, LIST_PAGES, SEARCH_TYPE_PAGES } from '../../constants/Routes';
import { getListPageRoute, getSearchRoute } from '../../utilities/route-utils';
import { SUPPORT_EMAIL, SUPPORT_URL } from '../../constants/App';
import { FreeTrialUpgradeButton } from './FreeTrialUpgradeButton';
import { useUser } from '../../hooks/useUser';
import { doesUserHaveEmailCampaignsAccess } from '../../utilities/EmailCampaignsUtil/EmailCampaignsUtil';
import { Tooltip } from '../../elements/Tooltip/Tooltip';
import { useCreditsUsage } from '../../hooks/useCredits';

const LogoLinkWrapper = styled.div`
  a {
    display: block;
  }
`;

const AppProfile = styled(AppProfileMenu)`
  margin-left: 1rem;
`;

const NAV_LINK_STYLES = css`
  display: inline-block;
  position: relative;
  padding: 2rem 1rem;
  font-weight: 600;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: ${(props) => props.theme.palette.secondary.main};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    opacity: 0;
  }

  &.active {
    &:before {
      opacity: 1;
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:not(.active):hover {
    &:before {
      opacity: 0.5;
    }
  }
`;

export const StyledNavLink = styled(NavLink)`${NAV_LINK_STYLES}`;

const DisabledLink = styled.span`
  display: inline-block;
  position: relative;
  padding: 2rem 1rem;
  font-weight: 600;
  opacity: 0.5;
  cursor: not-allowed;
`;
export const ExternalNavLink = styled.a`${NAV_LINK_STYLES}`;

const NavigationLeftSide = styled.div`
  margin-left: 1.5rem;
`;

const NavigationRightSide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
`;

const AppNavBar = withStyles(
  (theme) => {
    return {
      root: {
        backgroundColor: '#fff',
        color: '#000',
        fontSize: '0.8125rem'
      }
    };
  },
  { withTheme: true }
)(AppBar);

const LISTS_SUB_NAV_ITEMS: IAppSubNavigationItem[] = [
  {
    to: getListPageRoute(LIST_PAGES.EXPORT),
    label: 'Exports',
    type: SubNavItemType.APP
  },
  {
    to: getListPageRoute(LIST_PAGES.SUPPRESSION),
    label: 'Suppression',
    type: SubNavItemType.APP
  },
  {
    to: getListPageRoute(LIST_PAGES.SAVED_SEARCH),
    label: 'Saved Searches',
    type: SubNavItemType.APP
  },
  {
    to: getListPageRoute(LIST_PAGES.COMPANY_MATCH),
    label: 'Company Match',
    type: SubNavItemType.APP
  },
  {
    to: getListPageRoute(LIST_PAGES.ENHANCEMENT),
    label: 'Enhancement',
    type: SubNavItemType.APP
  }
];

const HELP_SUB_NAV_ITEMS: IAppSubNavigationItem[] = [
  {
    to: SUPPORT_URL,
    label: 'Support Videos',
    type: SubNavItemType.EXTERNAL
  },
  {
    label: `Have any questions?\n${SUPPORT_EMAIL}`,
    type: SubNavItemType.DISPLAY
  }
];

// eslint-disable-next-line react/prop-types
export const AppNavigation = () => {
  const { user, isInternal } = useUser();
  const { isFreeTier, isEnterprise, isOBVPartner } = useCreditsUsage();
  const contentConnectUser = doesUserHaveEmailCampaignsAccess(isInternal, isEnterprise, isOBVPartner, user);

  return (
    <AppNavBar position="sticky">
      <Toolbar>
        <LogoLinkWrapper>
          <Link component={NavLink} to={getSearchRoute(SEARCH_TYPE_PAGES.CONTACT)}>
            <Logo size={LogoSize.MEDIUM} />
          </Link>
        </LogoLinkWrapper>
        <AppSearchBar />
        <NavigationLeftSide>
          <Link component={StyledNavLink} to={getSearchRoute(SEARCH_TYPE_PAGES.CONTACT)} color="textPrimary">
            Advanced Search
          </Link>
        </NavigationLeftSide>
        <NavigationRightSide>
          {user && isFreeTier && (
            <FreeTrialUpgradeButton />
          )}
          {user && isInternal && (
            <Link component={StyledNavLink} to={APP_ROUTES.ADMIN()} color="textPrimary">
              Internal Admin
            </Link>
          )}
          {user && contentConnectUser && (
            <Link component={StyledNavLink} to={APP_ROUTES.EMAIL_CAMPAIGNS_DASHBOARD()} color="textPrimary">
              Email Campaigns
            </Link>
          )}
          {user && !contentConnectUser && (
            <Tooltip title='Want us to run email campaigns for you? Contact us to learn more.'>
              <DisabledLink>
                Email Campaigns
              </DisabledLink>
            </Tooltip>
          )}
          <AppLinkWithSubNavigation
            to={getListPageRoute(LIST_PAGES.EXPORT)}
            label={'Lists'}
            subNavItems={LISTS_SUB_NAV_ITEMS}
            basePath={APP_ROUTES.LISTS_BASE()}
          />
          <Link component={StyledNavLink} to={APP_ROUTES.INTENT_BASE()} color="textPrimary">
            Intent
          </Link>
          <AppLinkWithSubNavigation
            isExternal
            to={SUPPORT_URL}
            label='Help'
            subNavItems={HELP_SUB_NAV_ITEMS}
          />
          <AppProfile />
        </NavigationRightSide>
      </Toolbar>
    </AppNavBar>
  );
};
